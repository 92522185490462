<template>
  <div>
    <!--个人信息的表单区域 -->
    <el-form
      ref="submitObj"
      :model="submitObj"
      class="submitObj"
      label-width="130px"
      :rules="rules"
    >
      <el-row type="flex">
        <el-col>
          <el-form-item label="性别" prop="gender">
            <el-select v-model="submitObj.gender" placeholder="请选择您的性别">
              <el-option
                v-for="item in genderList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="出生年月" prop="date_of_birth">
            <el-date-picker
              v-model="submitObj.date_of_birth"
              type="month"
              placeholder="选择出生年月"
              value-format="yyyy-MM"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row type="flex">
        <el-col>
          <el-form-item label="真实姓名" prop="name">
            <el-input v-model="submitObj.name" placeholder="请填写您的姓名"></el-input>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="身份" prop="identity">
            <el-select v-model="submitObj.identity" placeholder="请选择您的身份">
              <el-option
                v-for="item in identityList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row type="flex">
        <el-col>
          <el-form-item label="年龄" prop="age">
            <el-input v-model="submitObj.age" placeholder="请填写您的年龄"></el-input>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="手机号" prop="phone_number">
            <el-input v-model="submitObj.phone_number" placeholder="请填写您的手机号"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <!-- <el-row type="flex">
        <el-col>
          <el-form-item label="固定电话" prop="home_phone_number">
            <el-input v-model="submitObj.home_phone_number" placeholder="请填写您的固定电话"></el-input>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="微信号" prop="wechat">
            <el-input v-model="submitObj.wechat" placeholder="请填写您的微信号"></el-input>
          </el-form-item>
        </el-col>
      </el-row> -->

      <el-row type="flex">
        <el-col>
          <el-col>
            <el-form-item label="籍贯" prop="birthplace">
              <el-input v-model="submitObj.birthplace" placeholder="请填写您的籍贯"></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-form-item label="QQ" prop="QQ">
            <el-input v-model="submitObj.QQ" placeholder="请填写您的QQ"></el-input>
          </el-form-item> -->
        </el-col>
        <el-col>
          <el-form-item label="邮箱" prop="email">
            <el-input v-model="submitObj.email" placeholder="请填写您的邮箱"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row type="flex">
        <el-col>
          <el-form-item label="简历名称" prop="resume_name">
            <el-input
              v-model="submitObj.resume_name"
              placeholder="建议格式 xxx-岗位-2年/2023届毕业"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col></el-col>
      </el-row>

      <!-- <el-row type="flex">
        <el-col>
          <el-form-item label="民族" prop="ethnic">
            <el-select v-model="submitObj.ethnic" placeholder="请选择您的民族">
              <el-option v-for="item in ethnicList" :key="item.id" :label="item.name" :value="item.name"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row> -->
      <!--
      <el-row type="flex">
        <el-col>
          <el-form-item label="现居城市">
            <el-cascader
              class="current"
              ref="currentLocation"
              :options="regionData"
              @change="currentChange"
              v-model="submitObj.current_location"
              :placeholder="submitObj.current_location || '请选择现居城市'"
            ></el-cascader>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="详细地址" prop="detailed_location">
            <el-input v-model="submitObj.detailed_location" placeholder="请填写您的详细地址"></el-input>
          </el-form-item>
        </el-col>
      </el-row> -->

      <!-- <el-row type="flex">
        <el-col>
          <el-form-item label="邮编" prop="zipcode">
            <el-input v-model="submitObj.zipcode" placeholder="请填写邮编"></el-input>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="婚姻状况" prop="marital_status">
            <el-select v-model="submitObj.marital_status" placeholder="请选择您的婚姻状况">
              <el-option
                v-for="item in maritalStatusList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row> -->

      <!-- <el-row type="flex">
        <el-col>
          <el-form-item label="工作年限" prop="num_work_experience">
            <el-input placeholder="请填写您的工作年限" v-model="submitObj.num_work_experience">
              <span slot="suffix">年</span>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col></el-col>
      </el-row> -->

      <div class="BottomButton">
        <div class="add-box">
          <el-button @click="onSubmit" class="onSubmit" :loading="isLoading">保存</el-button>
        </div>
        <div>
          <!-- <el-button type="warning" class="empty" @click="resetForm">重置</el-button> -->
        </div>
      </div>
    </el-form>
    <!-- 个人信息的表单区域 -->
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  data() {
    // 手机号严格校验
    let validatePhone_number = (rule, value, callback) => {
      if (
        !/^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1589]))\d{8}$/.test(
          value
        )
      ) {
        callback(new Error('请输入正确的手机号格式'))
      } else {
        callback()
      }
    }
    // 邮箱号严格校验
    let validatePhone_email = (rule, value, callback) => {
      if (!/^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/.test(value)) {
        callback(new Error('请输入正确的邮箱格式'))
      } else {
        callback()
      }
    }
    return {
      decide: {
        value: null,
        text: '个人信息'
      },
      isLoading: false,
      // unserInfoDecide: 1, //0显示用户本地上传的简历信息 1显示已经诊断过的简历信息
      // regionData: regionData, //城市列表
      // ethnicList: [], //民族列表

      // 个人信息的校验
      rules: {
        name: [{ required: true, message: '请填写您的姓名', trigger: 'change' }],
        identity: [{ required: true, message: '请选择您的身份', trigger: 'change' }],
        gender: [{ required: true, message: '请选择您的性别', trigger: 'change' }],
        date_of_birth: [{ required: true, message: '请选择您的出生年月', trigger: 'change' }],
        age: [{ required: true, message: '请填写您的年龄', trigger: 'change' }],
        phone_number: [
          { required: true, message: '请填写您的手机号', trigger: 'change' },
          { validator: validatePhone_number, trigger: 'change' }
        ],
        email: [
          { required: true, message: '请填写您的邮箱', trigger: 'change' },
          { validator: validatePhone_email, trigger: 'change' }
        ],
        birthplace: [{ required: true, message: '请填写您的籍贯', trigger: 'change' }],
        resume_name: [{ required: true, message: '请填写您的简历名称', trigger: 'change' }]
      },
      // -个人信息对象字段
      submitObj: {
        all_result_n: null,
        id: 0,
        name: '', // 姓名
        identity: '', // 身份
        gender: '', //性别
        date_of_birth: '', // 出生日期
        age: '', //年龄
        phone_number: '', // 手机号
        email: '', //邮箱
        birthplace: '', // 籍贯地区
        resume_name: '' // 简历名称
      },
      identityList: [
        {
          label: '在校生',
          value: 1
        },
        {
          label: '应届生',
          value: 2
        },
        {
          label: '社会求职',
          value: 3
        }
      ],
      genderList: [
        {
          label: '男',
          value: 1
        },
        {
          label: '女',
          value: 2
        }
      ],
      maritalStatusList: [
        {
          label: '未婚',
          value: '0'
        },
        {
          label: '已婚',
          value: '1'
        },
        {
          label: '离异',
          value: '3'
        },
        {
          label: '再婚',
          value: '4'
        }
      ]
    }
  },
  created() {
    if (JSON.stringify(this.$route.query) === '{}') {
      this.getResumeGenerateContentFn()
    } else if (this.$route.query.id) {
      this.submitObj.id = this.$route.query.id
      this.getResumeGenerateContentFn()
    } else if (this.$route.query.type && this.$route.query.type === 'add') {
    }
  },
  computed: {
    ...mapState(['currentResumeEditId', 'currentResumeInfo'])
  },
  methods: {
    ...mapActions(['getNationLists', 'getResumeGenerateContent', 'resumeGenerateSave']),

    async getResumeGenerateContentFn() {
      const obj = await this.getResumeGenerateContent({ id: this.submitObj.id })
      this.returnInfoFn(obj)
    },
    // 获取民族列表
    // async getNationListsFn() {
    //   this.ethnicList = await this.getNationLists()
    // },
    // 选择现住城市的方法
    // currentChange() {
    //   const res = this.$refs.currentLocation.getCheckedNodes()
    //   if (res.length) {
    //     this.submitObj.current_location = res[0].pathLabels[1]
    //   } else {
    //     this.submitObj.current_location = ''
    //   }
    // },
    // 保存按钮
    onSubmit() {
      this.$refs.submitObj.validate(async valid => {
        if (valid) {
          this.isLoading = true
          if (this.currentResumeEditId) this.submitObj.id = this.currentResumeEditId
          const res = await this.resumeGenerateSave(this.submitObj)
          if (res.code == 200) {
            this.$message({
              message: '个人信息保存成功',
              type: 'success'
            })
            // this.getResumeGenerateContentFn()
          } else {
            this.$message.error('保存失败,请重试')
          }
          this.isLoading = false
        } else {
          return false
        }
      })
    },
    //点击预览简历触发校验
    onSubmitOk() {
      this.$refs.submitObj.validate(valid => {
        if (valid) {
          return (this.decide.value = true)
        } else {
          return (this.decide.value = false)
        }
      })
    },
    // 重置按钮
    resetForm() {
      this.submitObj = {
        // 姓名
        name: '',
        // 身份: 1在校生,2应届生,3社会求职
        identity: '',
        // 性别：0-未填写 1-男 2-女
        gender: '',
        // 出生日期，如 1981-02-01，如只有年月，则为1981-02
        date_of_birth: '',
        // 年龄
        age: '',
        // 手机
        phone_number: '',
        // 邮箱
        email: '',
        // ethnic: this.unserInfoDecide ? obj.ethnic : this.resumeAnalysisUserInfo.parsing_result.basic_info.ethnic || '',
        // 籍贯地区，如 广东广州，洛阳，江苏。以简历描述为准
        birthplace: '',
        resume_name: '' // 简历名称
      }
      // this.$refs.submitObj.resetFields()
      // this.$refs.currentLocation.panel.clearCheckedNodes()
    },
    // 回写信息
    returnInfoFn(obj) {
      let name = '',
        gender = '',
        date_of_birth = '',
        age = '',
        phone_number = '',
        email = '',
        birthplace = '',
        resume_name = ''

      if (obj) {
        // 接口正常回调
        if (obj.name) name = obj.name // 姓名
        if (parseInt(obj.gender)) gender = obj.gender // 性别：0-未填写 1-男 2-女
        if (obj.date_of_birth) date_of_birth = obj.date_of_birth // 出生日期
        if (obj.age > 0) age = obj.age // 年龄
        if (obj.birthplace) birthplace = obj.birthplace // 籍贯地区
        if (obj.phone_number) phone_number = obj.phone_number // 手机号
        if (obj.email) email = obj.email // 邮箱
        if (obj.resume_name) resume_name = obj.resume_name // 简历名称
        // 简历解析
        if (
          // 基础信息判断
          obj.parsing_result &&
          obj.parsing_result.basic_info
        ) {
          if (obj.parsing_result.basic_info.name) name = obj.parsing_result.basic_info.name // 姓名
          if (obj.parsing_result.basic_info.gender === '男') {
            // 性别：0-未填写 1-男 2-女
            gender = 1
          } else if (obj.parsing_result.basic_info.gender === '女') {
            gender = 2
          }
          if (obj.parsing_result.basic_info.date_of_birth)
            date_of_birth = obj.parsing_result.basic_info.date_of_birth // 出生日期
          if (obj.parsing_result.basic_info.age) age = obj.parsing_result.basic_info.age // 年龄
          if (obj.parsing_result.basic_info.birthplace)
            birthplace = obj.parsing_result.basic_info.birthplace // 籍贯地区
        }
        if (
          // 联系信息判断
          obj.parsing_result &&
          obj.parsing_result.contact_info
        ) {
          if (obj.parsing_result.contact_info.phone_number)
            phone_number = obj.parsing_result.contact_info.phone_number // 手机号
          if (obj.parsing_result.contact_info.email) email = obj.parsing_result.contact_info.email // 邮箱
        }
      }

      this.submitObj = {
        all_result_n: null,
        id: obj && obj.id ? obj.id : 0,
        name, // 姓名
        identity: obj && obj.identity ? obj.identity : '', //身份
        gender, //性别
        date_of_birth, //出生日期
        age, //年龄
        phone_number, //手机号
        email, //邮箱
        birthplace, //籍贯地区
        resume_name //简历名称
      }

      // this.submitObj = {
      //   id: obj && obj.id ? obj.id : 0,
      //   // 姓名
      //   name: this.unserInfoDecide
      //     ? obj.name || ''
      //     : this.currentResumeInfo.parsing_result.basic_info.name || '',
      //   // 身份: 1在校生,2应届生,3社会求职
      //   identity: this.unserInfoDecide ? obj.identity || '' : '',
      //   // 性别：0-未填写 1-男 2-女
      //   gender: this.unserInfoDecide
      //     ? obj.gender == 0
      //       ? ''
      //       : obj.gender || ''
      //     : this.currentResumeInfo.parsing_result.basic_info.gender === '男'
      //     ? 1
      //     : this.currentResumeInfo.parsing_result.basic_info.gender === '女'
      //     ? 2
      //     : '',
      //   // 出生日期，如 1981-02-01，如只有年月，则为1981-02
      //   date_of_birth: this.unserInfoDecide
      //     ? obj.date_of_birth || ''
      //     : this.currentResumeInfo.parsing_result.basic_info.date_of_birth ||
      //       '',
      //   // 年龄
      //   age: this.unserInfoDecide
      //     ? obj.age > 0
      //       ? obj.age
      //       : ''
      //     : this.currentResumeInfo.parsing_result.basic_info.age || null,
      //   // 手机
      //   phone_number: this.unserInfoDecide
      //     ? obj.phone_number || ''
      //     : this.currentResumeInfo.parsing_result.contact_info.phone_number ||
      //       '',
      //   // 固定电话
      //   // home_phone_number: this.unserInfoDecide
      //   //   ? obj.home_phone_number
      //   //   : this.currentResumeInfo.parsing_result.contact_info.home_phone_number || '',
      //   // 微信号
      //   // wechat: this.unserInfoDecide
      //   //   ? obj.wechat
      //   //   : this.currentResumeInfo.parsing_result.contact_info.wechat || '',
      //   // 邮箱
      //   email: this.unserInfoDecide
      //     ? obj.email || ''
      //     : this.currentResumeInfo.parsing_result.contact_info.email || '',
      //   // QQ
      //   // QQ: this.unserInfoDecide ? obj.QQ : this.currentResumeInfo.parsing_result.contact_info.QQ || '',
      //   // 身高
      //   // height: obj.height || null,
      //   // 体重
      //   // weight: obj.weight || null,
      //   // 民族
      //   // ethnic: this.unserInfoDecide ? obj.ethnic : this.currentResumeInfo.parsing_result.basic_info.ethnic || '',
      //   // 籍贯地区，如 广东广州，洛阳，江苏。以简历描述为准
      //   birthplace: this.unserInfoDecide
      //     ? obj.birthplace || ''
      //     : this.currentResumeInfo.parsing_result.basic_info.birthplace || ''
      //   // 所在地城市或区域:北京、南宁
      //   // current_location: this.unserInfoDecide
      //   //   ? obj.current_location
      //   //   : this.currentResumeInfo.parsing_result.basic_info.current_location || '',
      //   // 详细地址
      //   // detailed_location: this.unserInfoDecide
      //   //   ? obj.detailed_location
      //   //   : this.currentResumeInfo.parsing_result.basic_info.detailed_location || '',
      //   // 邮编
      //   // zipcode: this.unserInfoDecide
      //   //   ? obj.zipcode
      //   //   : this.currentResumeInfo.parsing_result.basic_info.zipcode || '',
      //   // 婚姻状况
      //   // marital_status: this.unserInfoDecide
      //   //   ? obj.marital_status
      //   //   : this.currentResumeInfo.parsing_result.basic_info.marital_status || '',
      //   // 工作年限：
      //   // num_work_experience: this.unserInfoDecide
      //   //   ? obj.num_work_experience === 0
      //   //     ? ''
      //   //     : obj.num_work_experience
      //   //   : this.currentResumeInfo.parsing_result.basic_info.num_work_experience === 0
      //   //   ? ''
      //   //   : this.currentResumeInfo.parsing_result.basic_info.num_work_experience
      // }
    }
  },
  watch: {
    currentResumeInfo(obj) {
      this.returnInfoFn(obj)
    }
  }
}
</script>

<style lang="stylus" scoped>
.BottomButton
  display flex
  margin-left 567px
  margin-top 30px
/deep/ .row-bg.el-row
  margin-left 438px
  margin-top 20px
/deep/ .el-button.empty.el-button--warning
  margin-left 20px
/deep/ .current.el-cascader
  .el-input__inner::placeholder
    color #606266
/deep/ .el-button.onSubmit.el-button--default
  color #fff
  height 40px
  width 110px
  background #003DBB
  border-radius 4px
/deep/ .el-button.onSubmit.el-button--default:hover
  opacity 0.8
</style>
