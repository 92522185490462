<template>
  <div>
    <!--个人信息的表单区域 -->
    <el-form
      ref="submitObj"
      :model="submitObj"
      class="submitObj"
      label-width="130px"
      :rules="rules"
    >
      <!-- <el-row type="flex">
        <el-col>
          <el-form-item label="真实姓名" prop="name">
            <el-input v-model="submitObj.name" placeholder="请填写您的姓名"></el-input>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="身份" prop="identity">
            <el-select v-model="submitObj.identity" placeholder="请选择您的身份">
              <el-option
                v-for="item in identityList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row> -->

      <!-- <el-row type="flex">
        <el-col>
          <el-form-item label="性别" prop="gender">
            <el-select v-model="submitObj.gender" placeholder="请选择您的性别">
              <el-option
                v-for="item in genderList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="出生年月" prop="date_of_birth">
            <el-date-picker
              v-model="submitObj.date_of_birth"
              type="month"
              placeholder="选择出生年月"
              value-format="yyyy-MM"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row> -->

      <!-- <el-row type="flex">
        <el-col>
          <el-form-item label="年龄" prop="age">
            <el-input v-model="submitObj.age" placeholder="请填写您的年龄"></el-input>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="手机号" prop="phone_number">
            <el-input v-model="submitObj.phone_number" placeholder="请填写您的手机号"></el-input>
          </el-form-item>
        </el-col>
      </el-row> -->

      <el-row type="flex">
        <el-col>
          <el-form-item label="固定电话" prop="home_phone_number">
            <el-input
              v-model="submitObj.home_phone_number"
              placeholder="请填写您的固定电话"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="微信号" prop="wechat">
            <el-input v-model="submitObj.wechat" placeholder="请填写您的微信号"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row type="flex">
        <el-col>
          <el-form-item label="QQ" prop="QQ">
            <el-input v-model="submitObj.QQ" placeholder="请填写您的QQ" type="number"></el-input>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="工作年限" prop="num_work_experience">
            <el-input
              placeholder="请填写您的工作年限"
              v-model="submitObj.num_work_experience"
              type="number"
            >
              <span slot="suffix">年</span>
            </el-input>
          </el-form-item>
          <!-- <el-form-item label="邮箱" prop="email">
            <el-input v-model="submitObj.email" placeholder="请填写您的邮箱"></el-input>
          </el-form-item> -->
        </el-col>
      </el-row>

      <el-row type="flex">
        <el-col>
          <el-form-item label="身高" prop="height">
            <el-input v-model="submitObj.height" placeholder="请填写您的身高" type="number">
              <span slot="suffix">cm</span>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="体重" prop="weight">
            <el-input v-model="submitObj.weight" placeholder="请填写您的体重" type="number">
              <span slot="suffix">kg</span>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row type="flex">
        <el-col>
          <el-form-item label="民族" prop="ethnic">
            <!-- <el-select v-model="submitObj.ethnic" placeholder="请选择您的民族">
              <el-option v-for="item in ethnicList" :key="item.id" :label="item.name" :value="item.name"></el-option>
            </el-select> -->
            <el-autocomplete
              value-key="name"
              v-model="submitObj.ethnic"
              :fetch-suggestions="querySearchAsync"
              placeholder="请输入内容"
            ></el-autocomplete>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="现居城市">
            <el-cascader
              class="current"
              ref="currentLocation"
              :options="regionData"
              @change="currentChange"
              :placeholder="submitObj.current_location || '请选择现居城市'"
              v-model="cityData"
            ></el-cascader>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row type="flex">
        <el-col>
          <el-form-item label="详细地址" prop="detailed_location">
            <el-input
              v-model="submitObj.detailed_location"
              placeholder="请填写您的详细地址"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="邮编" prop="zipcode">
            <el-input v-model="submitObj.zipcode" placeholder="请填写邮编"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row type="flex">
        <el-col>
          <el-form-item label="婚姻状况" prop="marital_status">
            <el-select v-model="submitObj.marital_status" placeholder="请选择您的婚姻状况">
              <el-option
                v-for="item in maritalStatusList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col></el-col>
      </el-row>

      <el-row type="flex">
        <el-col></el-col>
      </el-row>

      <div class="BottomButton">
        <div class="add-box">
          <el-button @click="onSubmit" class="onSubmit" :loading="isLoading">保存</el-button>
        </div>
        <div>
          <el-button class="empty" @click="resetForm">重置</el-button>
        </div>
      </div>
    </el-form>
    <!-- 个人信息的表单区域 -->
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { regionData } from 'element-china-area-data'
export default {
  data() {
    // 手机号严格校验
    let validatePhone_number = (rule, value, callback) => {
      if (
        !/^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1589]))\d{8}$/.test(
          value
        )
      ) {
        callback(new Error('请输入正确的手机号格式'))
      } else {
        callback()
      }
    }
    // 邮箱号严格校验
    let validatePhone_email = (rule, value, callback) => {
      if (!/^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/.test(value)) {
        callback(new Error('请输入正确的邮箱格式'))
      } else {
        callback()
      }
    }
    return {
      decide: {
        value: null,
        text: '个人信息'
      },
      isLoading: false,
      unserInfoDecide: 1, //0显示用户本地上传的简历信息 1显示已经诊断过的简历信息
      regionData: regionData, //城市列表
      ethnicList: [], //民族列表
      cityData: [], //已经存在得省市区id(进行回显)
      // 个人信息的校验
      rules: {
        // name: [{ required: true, message: '请填写您的姓名', trigger: 'blur' }],
        // identity: [
        //   { required: true, message: '请选择您的身份', trigger: 'blur' }
        // ],
        // gender: [
        //   { required: true, message: '请选择您的性别', trigger: 'blur' }
        // ],
        // date_of_birth: [
        //   { required: true, message: '请选择您的出生年月', trigger: 'blur' }
        // ],
        // age: [{ required: true, message: '请填写您的年龄', trigger: 'blur' }],
        // phone_number: [
        //   { required: true, message: '请填写您的手机号', trigger: 'blur' },
        //   { validator: validatePhone_number, trigger: 'blur' }
        // ],
        // email: [
        //   { required: true, message: '请填写您的邮箱', trigger: 'blur' },
        //   { validator: validatePhone_email, trigger: 'blur' }
        // ]
      },
      // -个人信息对象字段
      submitObj: {
        id: 0,
        all_result_n: null,
        QQ: '',
        current_location: '',
        detailed_location: '',
        ethnic: '',
        height: '',
        home_phone_number: '',
        marital_status: '',
        num_work_experience: '',
        wechat: '',
        weight: '',
        zipcode: '',
        district1: '',
        district2: '',
        district3: ''
      },
      identityList: [
        {
          label: '在校生',
          value: 1
        },
        {
          label: '应届生',
          value: 2
        },
        {
          label: '社会求职',
          value: 3
        }
      ],
      genderList: [
        {
          label: '男',
          value: 1
        },
        {
          label: '女',
          value: 2
        }
      ],
      maritalStatusList: [
        {
          label: '未婚',
          value: '0'
        },
        {
          label: '已婚',
          value: '1'
        },
        {
          label: '离异',
          value: '3'
        },
        {
          label: '再婚',
          value: '4'
        }
      ]
    }
  },
  created() {
    if (JSON.stringify(this.$route.query) === '{}') {
      this.getResumeGenerateContentFn()
    } else if (this.$route.query.id) {
      this.submitObj.id = this.$route.query.id
      this.getResumeGenerateContentFn()
    } else if (this.$route.query.type && this.$route.query.type === 'add') {
    }
    this.getNationListsFn()
  },
  computed: {
    ...mapState(['currentResumeInfo', 'currentResumeEditId'])
  },
  methods: {
    ...mapActions(['getNationLists', 'getResumeGenerateContent', 'resumeGenerateSave']),

    async getResumeGenerateContentFn() {
      if (this.currentResumeEditId) this.submitObj.id = this.currentResumeEditId
      const obj = await this.getResumeGenerateContent({ id: this.submitObj.id })
      this.returnInfoFn(obj)
    },
    // 获取民族列表
    async getNationListsFn() {
      this.ethnicList = await this.getNationLists()
    },
    querySearchAsync(queryString, cb) {
      var restaurants = this.ethnicList
      var results = queryString
        ? restaurants.filter(this.createStateFilter(queryString))
        : restaurants
      cb(results)
    },
    createStateFilter(queryString) {
      return restaurants => {
        return restaurants.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0
      }
    },
    // 选择现住城市的方法
    currentChange() {
      const res = this.$refs.currentLocation.getCheckedNodes()
      if (res.length) {
        this.submitObj.current_location = res[0].pathLabels[1]
        this.submitObj.district1 = res[0].path[0]
        this.submitObj.district2 = res[0].path[1]
        this.submitObj.district3 = res[0].path[2]
      } else {
        this.submitObj.current_location = ''
      }
    },
    // 保存按钮
    onSubmit() {
      this.$refs.submitObj.validate(async valid => {
        if (valid) {
          this.unserInfoDecide = 1
          this.isLoading = true
          if (this.currentResumeEditId) this.submitObj.id = this.currentResumeEditId
          const res = await this.resumeGenerateSave(this.submitObj)
          if (res.code == 200) {
            this.$message({
              message: '个人信息保存成功',
              type: 'success'
            })
            // this.getResumeGenerateContentFn()
          } else {
            this.$message.error('保存失败,请重试')
          }
          this.isLoading = false
        } else {
          return false
        }
      })
    },
    //点击预览简历触发校验
    onSubmitOk() {
      this.$refs.submitObj.validate(valid => {
        if (valid) {
          return (this.decide.value = true)
        } else {
          return (this.decide.value = false)
        }
      })
    },
    //回显方法
    returnInfoFn(obj) {
      if (obj) {
        let QQ = '',
          current_location = '',
          detailed_location = '',
          ethnic = '',
          height = '',
          home_phone_number = '',
          marital_status = '',
          num_work_experience = '',
          wechat = '',
          weight = '',
          zipcode = '',
          district1 = '',
          district2 = '',
          district3 = ''

        // 接口正常回调
        if (obj.QQ) QQ = obj.QQ // QQ
        if (obj.current_location) current_location = obj.current_location // 所在地城市或区域
        if (obj.detailed_location) detailed_location = obj.detailed_location // 详细地址
        if (obj.ethnic) ethnic = obj.ethnic // 民族
        if (obj.height) height = obj.height // 身高
        if (obj.home_phone_number) home_phone_number = obj.home_phone_number // 固定电话
        if (obj.marital_status) marital_status = obj.marital_status // 婚姻状况
        if (obj.num_work_experience) num_work_experience = obj.num_work_experience // 工作年限
        if (obj.wechat) wechat = obj.wechat // 微信号
        if (obj.weight) weight = obj.weight // 体重
        if (obj.zipcode) zipcode = obj.zipcode // 邮编
        if (obj.district1 && obj.district2 && obj.district3) {
          this.cityData = [obj.district1, obj.district2, obj.district3]
          district1 = obj.district1
          district2 = obj.district2
          district3 = obj.district3
        }
        // 简历解析
        if (
          // 基础信息判断
          obj.parsing_result &&
          obj.parsing_result.basic_info
        ) {
          if (obj.parsing_result.basic_info.current_location)
            current_location = obj.parsing_result.basic_info.current_location // 所在地城市或区域
          if (obj.parsing_result.basic_info.detailed_location)
            detailed_location = obj.parsing_result.basic_info.detailed_location // 详细地址
          if (obj.parsing_result.basic_info.ethnic) ethnic = obj.parsing_result.basic_info.ethnic // 民族
          if (obj.parsing_result.basic_info.height) height = obj.parsing_result.basic_info.height // 身高
          if (obj.parsing_result.basic_info.marital_status)
            marital_status = obj.parsing_result.basic_info.marital_status // 婚姻状况
          if (obj.parsing_result.basic_info.num_work_experience)
            num_work_experience = obj.parsing_result.basic_info.num_work_experience // 工作年限
          if (obj.parsing_result.basic_info.weight) weight = obj.parsing_result.basic_info.weight // 体重
          if (obj.parsing_result.basic_info.zipcode) zipcode = obj.parsing_result.basic_info.zipcode // 邮编
        }
        if (
          // 联系信息判断
          obj.parsing_result &&
          obj.parsing_result.contact_info
        ) {
          if (obj.parsing_result.contact_info.QQ) QQ = obj.parsing_result.basic_info.QQ // QQ
          if (obj.parsing_result.contact_info.home_phone_number)
            home_phone_number = obj.parsing_result.basic_info.home_phone_number // 固定电话
          if (obj.parsing_result.contact_info.wechat) wechat = obj.parsing_result.basic_info.wechat // 微信号
        }

        this.submitObj = {
          all_result_n: null,
          id: obj && obj.id ? obj.id : 0,
          QQ,
          current_location,
          detailed_location,
          ethnic,
          height,
          home_phone_number,
          marital_status,
          num_work_experience,
          wechat,
          weight,
          zipcode,
          district1,
          district2,
          district3
        }
      }
      // this.submitObj = {
      //   all_result_n: null,
      //   id: obj && obj.id ? obj.id : 0,
      //   // 姓名
      //   // name: this.unserInfoDecide ? obj.name : this.currentResumeInfo.parsing_result.basic_info.name || '',
      //   // 身份: 1在校生,2应届生,3社会求职
      //   // identity: obj.identity || '',
      //   // 性别：0-未填写 1-男 2-女
      //   // gender: this.unserInfoDecide
      //   //   ? obj.gender == 0
      //   //     ? ''
      //   //     : obj.gender
      //   //   : this.currentResumeInfo.parsing_result.basic_info.gender === '男'
      //   //   ? 1
      //   //   : this.currentResumeInfo.parsing_result.basic_info.gender === '女'
      //   //   ? 2
      //   //   : '',
      //   // 出生日期，如 1981-02-01，如只有年月，则为1981-02
      //   // date_of_birth: this.unserInfoDecide
      //   //   ? obj.date_of_birth
      //   //   : this.currentResumeInfo.parsing_result.basic_info.date_of_birth || '',
      //   // 年龄
      //   // age: this.unserInfoDecide ? obj.age : this.currentResumeInfo.parsing_result.basic_info.age || null,
      //   // 手机
      //   // phone_number: this.unserInfoDecide
      //   //   ? obj.phone_number
      //   //   : this.currentResumeInfo.parsing_result.contact_info.phone_number || '',
      //   // 固定电话
      //   home_phone_number: this.unserInfoDecide
      //     ? obj.home_phone_number || ''
      //     : this.currentResumeInfo.parsing_result.contact_info
      //         .home_phone_number || '',
      //   // 微信号
      //   wechat: this.unserInfoDecide
      //     ? obj.wechat || ''
      //     : this.currentResumeInfo.parsing_result.contact_info.wechat || '',
      //   // 邮箱
      //   // email: this.unserInfoDecide ? obj.email : this.currentResumeInfo.parsing_result.contact_info.email || '',
      //   // QQ
      //   QQ: this.unserInfoDecide
      //     ? obj.QQ || ''
      //     : this.currentResumeInfo.parsing_result.contact_info.QQ || '',
      //   // 身高
      //   height: obj.height || '',
      //   // 体重
      //   weight: obj.weight || '',
      //   // 民族
      //   ethnic: this.unserInfoDecide
      //     ? obj.ethnic || ''
      //     : this.currentResumeInfo.parsing_result.basic_info.ethnic || '',
      //   // 籍贯地区，如 广东广州，洛阳，江苏。以简历描述为准
      //   // birthplace: this.unserInfoDecide
      //   //   ? obj.birthplace
      //   //   : this.currentResumeInfo.parsing_result.basic_info.birthplace || '',
      //   // 所在地城市或区域:北京、南宁
      //   current_location: this.unserInfoDecide
      //     ? obj.current_location || ''
      //     : this.currentResumeInfo.parsing_result.basic_info.current_location ||
      //       '',
      //   // 详细地址
      //   detailed_location: this.unserInfoDecide
      //     ? obj.detailed_location || ''
      //     : this.currentResumeInfo.parsing_result.basic_info
      //         .detailed_location || '',
      //   // 邮编
      //   zipcode: this.unserInfoDecide
      //     ? obj.zipcode || ''
      //     : this.currentResumeInfo.parsing_result.basic_info.zipcode || '',
      //   // 婚姻状况
      //   marital_status: this.unserInfoDecide
      //     ? obj.marital_status || ''
      //     : this.currentResumeInfo.parsing_result.basic_info.marital_status ||
      //       '',
      //   // 工作年限：
      //   num_work_experience: this.unserInfoDecide
      //     ? obj.num_work_experience === 0
      //       ? ''
      //       : obj.num_work_experience || ''
      //     : this.currentResumeInfo.parsing_result.basic_info
      //         .num_work_experience === 0
      //     ? ''
      //     : this.currentResumeInfo.parsing_result.basic_info.num_work_experience
      // }
    },
    // 重置按钮
    resetForm() {
      // this.$refs.submitObj.resetFields()
      this.submitObj = {
        // 固定电话
        home_phone_number: '',
        // 微信号
        wechat: '',
        // QQ
        QQ: '',
        // 身高
        height: '',
        // 体重
        weight: '',
        // 民族
        ethnic: '',

        // 所在地城市或区域:北京、南宁
        current_location: '',
        // 详细地址
        detailed_location: '',
        // 邮编
        zipcode: '',
        // 婚姻状况
        marital_status: '',
        // 工作年限：
        num_work_experience: '',
        district1: '',
        district2: '',
        district3: ''
      }
      this.$refs.currentLocation.panel.clearCheckedNodes()
    }
  },
  watch: {
    currentResumeInfo(obj) {
      this.returnInfoFn(obj)
    }
  }
}
</script>

<style lang="stylus" scoped>
.BottomButton
  display flex
  margin-left 567px
  margin-top 30px
/deep/ .row-bg.el-row
  margin-left 438px
  margin-top 20px
/deep/ .el-button.empty.el-button--default
  margin-left 20px
  width 110px
  height 40px
  background #fff
  border 1px solid #003dbb
  border-radius 4px
  font-family Source Han Sans CN
  font-weight 400
  color #1c52c2
  text-align center
/deep/ .current.el-cascader
  .el-input__inner::placeholder
    color #606266
/deep/ .el-button.el-button--default
  color #fff
  height 40px
  width 110px
  background #003DBB
  border-radius 4px
</style>
